
.search-display {
ul{
    list-style: none;
    display: inline-block;
    width: 420px;
    text-align: left;
}

ul li{
    display: block;
    padding: 15px 20px;
    background-color: #F8F8F8;
    color: #7B8585;
    margin-bottom: 3px;
    position: relative;
    
    transition: 0.3s;
}

ul li a{
    position: absolute;
    left: 160px;
    font-size: 12px;
    line-height: 16px;
    color: #969d9d;
}

ul li:hover{
    background-color:#d8f2f1;
}
input{
    border-radius: 5px 0px 0px 5px;
}
}
