h4{
        color: #253858;
        margin-bottom: .8rem;
        position: relative;
        font-family: 'Raleway', sans-serif;
        font-size: 1.5rem;
    }
    p{
        margin-top: 0;
        margin-bottom: 1rem;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        color: rgb(113, 120, 126);
        font-family: 'Lato', sans-serif;
    }
    /*[404 error page]
--------------------------*/
    .error-content {
        padding: 0 0 70px;
    }
    .error-text{
        text-align: center;
    }
    .error {
        font-size: 180px;
        font-weight: 100;
    }
    @keyframes bob {
        0% {
            top: 0;
        }
        50% {
            top: 0.2em;
        }
    }
    .im-sheep {
        display: inline-block;
        position: relative;
        font-size: 1em;
        margin-bottom: 70px;
    }
    .im-sheep * {
        transition: transform 0.3s;
    }
    .im-sheep .top {
        position: relative;
        top: 0;
        animation: bob 1s infinite;
    }
    .im-sheep:hover .head {
        transform: rotate(0deg);
    }
    .im-sheep:hover .head .im-eye {
        width: 1.25em;
        height: 1.25em;
    }
    .im-sheep:hover .head .im-eye:before {
        right: 30%;
    }
    .im-sheep:hover .top {
        animation-play-state: paused;
    }
    .im-sheep .head {
        display: inline-block;
        width: 5em;
        height: 5em;
        border-radius: 100%;
        background: #253858;
        vertical-align: middle;
        position: relative;
        top: 1em;
        transform: rotate(30deg);
    }
    .im-sheep .head:before {
        content: '';
        display: inline-block;
        width: 80%;
        height: 50%;
        background: #253858;
        position: absolute;
        bottom: 0;
        right: -10%;
        border-radius: 50% 40%;
    }
    .im-sheep .head:hover .im-ear.one, .im-sheep .head:hover .im-ear.two {
        transform: rotate(0deg);
    }
    .im-sheep .head .im-eye {
        display: inline-block;
        width: 1em;
        height: 1em;
        border-radius: 100%;
        background: white;
        position: absolute;
        overflow: hidden;
    }
    .im-sheep .head .im-eye:before {
        content: '';
        display: inline-block;
        background: black;
        width: 50%;
        height: 50%;
        border-radius: 100%;
        position: absolute;
        right: 10%;
        bottom: 10%;
        transition: all 0.3s;
    }
    .im-sheep .head .im-eye.one {
        right: -2%;
        top: 1.7em;
    }
    .im-sheep .head .im-eye.two {
        right: 2.5em;
        top: 1.7em;
    }
    .im-sheep .head .im-ear {
        background: #253858;
        width: 50%;
        height: 30%;
        border-radius: 100%;
        position: absolute;
    }
    .im-sheep .head .im-ear.one {
        left: -10%;
        top: 5%;
        transform: rotate(-30deg);
    }
    .im-sheep .head .im-ear.two {
        top: 2%;
        right: -5%;
        transform: rotate(20deg);
    }
    .im-sheep .body {
        display: inline-block;
        width: 7em;
        height: 7em;
        border-radius: 100%;
        background: #0054D1;
        position: relative;
        vertical-align: middle;
        margin-right: -3em;
    }
    .im-sheep .im-legs {
        display: inline-block;
        position: absolute;
        top: 80%;
        left: 10%;
        z-index: -1;
    }
    .im-sheep .im-legs .im-leg {
        display: inline-block;
        background: #141214;
        width: 0.5em;
        height: 2.5em;
        margin: 0.2em;
    }
    .im-sheep::before {
        left: 0;
        content: '';
        display: inline-block;
        position: absolute;
        top: 112%;
        width: 100%;
        height: 18%;
        border-radius: 100%;
        background: rgba(0, 0, 0, 0.2);
    }
